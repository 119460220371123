import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';


//#region Scene

// Get canvas
const canvas = document.querySelector('#leavesCanvas');

// Setup scene
const scene = new THREE.Scene();
// scene.add(new THREE.GridHelper(10, 100, "aqua", "aqua"));

// Initialize WebGL Renderer
const renderer = new THREE.WebGLRenderer({ canvas: leavesCanvas, alpha: true });
renderer.setSize(innerWidth, innerHeight);

//#endregion


//#region Camera

const fov = 35;
const aspect = innerWidth/innerHeight;
const near = 0.1;
const far = 100;
const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
camera.position.z = 5;
// camera.position.set(0, 1, 5);
// camera.lookAt(scene.position);

//#endregion


//#region Orbit
const controls = new OrbitControls(camera, canvas);
controls.enableZoom = false;
controls.enablePan = false;
controls.minPolarAngle = Math.PI/2;
controls.maxPolarAngle = Math.PI/2;
controls.update();
//#endregion


//#region Animation

var clock = new THREE.Clock();

function animate() {

   renderer.setAnimationLoop(() => {

      // Render the scene
      renderer.render(scene, camera);

      let t = clock.getElapsedTime();

      for (let i = 0; i < leavesModels.length; i++) {
         const offset = Math.sin((2 * Math.PI * 2) + t) * leavesModels[i].float;
         const leaf = leavesModels[i].model;
         leaf.position.y = offset;
         leaf.rotation.y = offset;
      }

   });

}

//#endregion


//#region 3D Models

let hasLoadedFinalModel = false;
const objLoader = new OBJLoader();
const mtlLoader = new MTLLoader();
let leavesModels = [];
let material = {
   mtl: 'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_pljcaw.mtl',
   tex: 'https://res.cloudinary.com/dyl1v35zu/image/upload/v1625920260/Leaf_Tex_ugemeg.jpg'
};
let models = [
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_1_r13yhj.obj',
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_2_tc6znp.obj',
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_3_davoem.obj',
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_4_jtpeee.obj',
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_5_l0ngyl.obj',
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_6_qhb3sl.obj',
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_7_y2ovkv.obj',
   'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_8_ux1dgp.obj'
];

loadLeaves();
function loadLeaves() {

   mtlLoader.load(material.mtl, (mtl) => {

      mtl.preload();
      objLoader.setMaterials(mtl);

      var texture = new THREE.TextureLoader().load(material.tex, (tex) => {

         models.forEach((model, i) => {
            objLoader.load(model, (obj) => {

               hasLoadedFinalModel = (i == models.length - 1);
               leavesModels.push({ model: obj, float: (Math.random() * (0.19 - 0.09) + 0.09).toFixed(2) });

               // Apply texture to model
               obj.traverse((child) => {
                  if (child instanceof THREE.Mesh) child.material.map = texture;
               });

               // Add model to scene
               scene.add(obj);

               // Conceal loader 
               if (hasLoadedFinalModel) {
                  document.querySelector('.leaves-loader').remove();
                  renderer.render(scene, camera);
                  animate();
               }

            });
         });

      });

   });

}

//#endregion


//#region Lighting

// var directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
// directionalLight.position.setScalar(0.3);
// scene.add(directionalLight);

scene.add(new THREE.AmbientLight(0xffffff, 0.7));

var spotLight = new THREE.SpotLight(0xffffff, 1.0);
spotLight.position.set(0, -90, -50);
scene.add(spotLight);

var spotLightB = new THREE.SpotLight(0xffffff, 0.9);
spotLightB.position.set(-50, 70, -10);
scene.add(spotLightB);

//#endregion
