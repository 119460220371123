let isMobileMenuVisible = false;
let mobileMenuToggler = document.getElementById('mobile-menu-toggler');
let mobileMenu = document.getElementsByClassName('m-nav')[0];
let mobileMenuLinks = document.querySelectorAll('.nav-link');
let socialMedia = document.getElementsByClassName('social-media')[0];

let careerSection = document.getElementById("career-section");
let toolsSection = document.getElementById("tools-section");
let publicationsSection = document.getElementById("publications-section");
let projectsSection = document.getElementById("projects-section");
let contactSection = document.getElementById("contact-section");

mobileMenuToggler.addEventListener("click", () => {

    // Toggle display state
    isMobileMenuVisible = !isMobileMenuVisible;

    if (isMobileMenuVisible) {
        openMobileMenu();
        mobileMenuLinks.forEach(l => {l.addEventListener('click', () => closeMobileMenu())});
    }
    else {
        closeMobileMenu();
    }

});


//#region Navbar links navigation

let careerLink = document.getElementById("career-link");
careerLink.addEventListener("click", () => {
    careerSection.scrollIntoView({ behavior: 'smooth' });
});

//#endregion

function openMobileMenu() {
    mobileMenuToggler.classList.add('open');
    mobileMenu.classList.add('existing');
    socialMedia.style.display = "unset";
    setTimeout(() => { 
        mobileMenu.classList.add('open');
        socialMedia.style.opacity = "1";
    }, 300);
}

function closeMobileMenu() {
    mobileMenuToggler.classList.remove('open');
    mobileMenu.classList.remove('open');
    socialMedia.style.opacity = "0";
    setTimeout(() => { 
        mobileMenu.classList.remove('existing');
        socialMedia.style.display = "none";
    }, 300);
}
