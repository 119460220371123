import Typewriter from 'typewriter-effect/dist/core';
require('./shared/cursor');
require('./shared/leaves');
require('./sections/hero');

//#region Feather Icons

const feather = require('feather-icons');
feather.replace()

//#endregion


//#region Typewriter

new Typewriter('#typewriter', {
   strings: ['Performant', 'Scalable', 'Maintainable', 'Reactive', 'Robust', 'Testable', 'Clean'],
   autoStart: true,
   loop: true
});

//#endregion
